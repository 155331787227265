<template>
  <div class="my">
    <el-container>
      <el-aside>
        <ul class="menu">
          <!-- <li
            @click="changeItem('first')"
            class="menu__item flex"
            :class="[activeSecondRouteName == 'myCenter' ? 'active' : '']"
          >
            <img
              src="@/assets/images/icon_navleft_home_selected@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
              class="front"
              v-if="activeSecondRouteName == 'myCenter'"
            />
            <img
              v-else
              class="front"
              src="@/assets/images/icon_navleft_home_normal@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
            />
            <span>{{ $t("el.MyHome.MyHomePage") }}</span>
          </li> -->
          <li
            @click="changeItem('secend')"
            class="menu__item flex"
            :class="[
              activeSecondRouteName == 'classManage' ||
              activeSecondRouteName == 'studentManage' ||
              activeSecondRouteName == 'classStudentManage'
                ? 'active'
                : '',
            ]"
          >
            <img
              src="@/assets/images/icon_navleft_class_selected@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
              v-if="
                activeSecondRouteName == 'classManage' ||
                activeSecondRouteName == 'studentManage' ||
                activeSecondRouteName == 'classStudentManage'
              "
            />
            <img
              v-else
              src="@/assets/images/icon_navleft_class_normal@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
            />
            <span>{{ $t("el.MyHome.classManage") }}</span>
          </li>
          <!-- <li
            @click="changeItem('third')"
            class="menu__item flex"
            :class="[
              activeSecondRouteName == 'manageCourseClass' ||
              activeSecondRouteName == 'manageCourseClassStudent'
                ? 'active'
                : '',
            ]"
          >
            <img
              src="@/assets/images/icon_navleft_class_selected@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
              v-if="
                activeSecondRouteName == 'manageCourseClass' ||
                activeSecondRouteName == 'manageCourseClassStudent'
              "
            />
            <img
              v-else
              src="@/assets/images/icon_navleft_class_normal@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
            />
            <span>{{ $t("el.MyHome.courseClass") }}</span>
          </li> -->
        </ul>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "my",
  data() {
    return {
      active: "secend",
      activeSecondRouteName: "classManage",
    };
  },
  computed: {},
  created() {},
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.activeSecondRouteName = newVal.name;
      },
      immediate: true,
    },
  },
  methods: {
    changeItem(flag) {
      this.active = flag;
      var pathname =
        flag === "first"
          ? "myCenter"
          : flag === "secend"
          ? "classManage"
          : "manageCourseClass";
      if (pathname !== this.activeSecondRouteName) {
        this.$router.push({
          name: pathname,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.my {
  .el-container {
    padding-top: 25px;
  }
  .el-aside {
    height: 565px;
    background-color: #fff;
    margin-right: 22px;
    overflow: visible;
    ul {
      padding-left: 0;
    }
    .menu__item {
      width: 100%;
      // padding-right: 10px;
      height: 40px;
      text-align: center;
      font-size: 14px;
      color: #444444;
      margin-bottom: 15px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        background: @themeBlue;
        box-shadow: 0px 3px 10px 0px rgba(172, 178, 193, 0.36);
        border-radius: 0px 6px 6px 0px;
        color: #fff;
      }
      div {
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        display: block;
      }
      span {
      }
    }
  }
  .el-main {
    padding-bottom: 0;
    background-color: #f6f8fc;
    min-height: 565px;
  }
}
@media screen and (min-width: 1620px) {
  .my .el-aside {
    width: 170px !important;
  }
}
@media screen and (max-width: 1619px) {
  .my .el-aside {
    width: 150px !important;
  }
  .en-status .my .el-aside {
    width: 170px !important;
  }
}
</style>
