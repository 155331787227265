var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my"},[_c('el-container',[_c('el-aside',[_c('ul',{staticClass:"menu"},[_c('li',{staticClass:"menu__item flex",class:[
            _vm.activeSecondRouteName == 'classManage' ||
            _vm.activeSecondRouteName == 'studentManage' ||
            _vm.activeSecondRouteName == 'classStudentManage'
              ? 'active'
              : '',
          ],on:{"click":function($event){return _vm.changeItem('secend')}}},[(
              _vm.activeSecondRouteName == 'classManage' ||
              _vm.activeSecondRouteName == 'studentManage' ||
              _vm.activeSecondRouteName == 'classStudentManage'
            )?_c('img',{attrs:{"src":require("@/assets/images/icon_navleft_class_selected@2x.png"),"alt":_vm.$t('el.common.centerName'),"title":_vm.$t('el.common.centerName')}}):_c('img',{attrs:{"src":require("@/assets/images/icon_navleft_class_normal@2x.png"),"alt":_vm.$t('el.common.centerName'),"title":_vm.$t('el.common.centerName')}}),_c('span',[_vm._v(_vm._s(_vm.$t("el.MyHome.classManage")))])])])]),_c('el-main',[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }